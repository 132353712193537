@import "/home/runner/work/vi-saas-frontend/vi-saas-frontend/src/resources/styles/settings.scss"; @import "/home/runner/work/vi-saas-frontend/vi-saas-frontend/src/extensions/resources/styles/settings.scss";  $iconSize: 32px;

.serviceExplanation {
	&__infoItem {
		display: flex;

		svg {
			min-width: $iconSize;
			height: $iconSize;
			margin-right: 14px;

			* {
				fill: $welcome-screen-icon-background;
			}
		}
	}

	&__infoContent {
		display: flex;
		flex-direction: column;
		text-align: left;

		.text {
			margin-top: $grid-base;
			color: $text-low-emphasis;
		}
	}
}
