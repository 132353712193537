@import "/home/runner/work/vi-saas-frontend/vi-saas-frontend/src/resources/styles/settings.scss"; @import "/home/runner/work/vi-saas-frontend/vi-saas-frontend/src/extensions/resources/styles/settings.scss";  $spinnerSize: 60px;

.loadingIndicator {
	width: $spinnerSize;
	height: $spinnerSize;
	position: relative;
	z-index: 300;

	&__bounce1,
	&__bounce2 {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-color: var(--skin-color-primary, $primary);
		opacity: 0.6;
		position: absolute;
		top: 0;
		left: 0;
		animation: loadingIndicator__bounceAnimation 2s infinite ease-in-out;
	}

	&__bounce2 {
		animation-delay: -1s;
	}

	@keyframes loadingIndicator__bounceAnimation {
		0%,
		100% {
			transform: scale(0);
		}
		50% {
			transform: scale(1);
		}
	}
}
