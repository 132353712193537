/* nunito-regular - latin */
@font-face {
	font-family: Nunito;
	font-style: normal;
	font-weight: 400;
	src:
		local(''),
		url('../fonts/nunito-v25-latin/nunito-v25-latin-regular.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('../fonts/nunito-v25-latin/nunito-v25-latin-regular.woff')
			format('woff'),
		/* Modern Browsers */
			url('../fonts/nunito-v25-latin/nunito-v25-latin-regular.ttf')
			format('truetype'); /* Safari, Android, iOS */
}

/* nunito-600 - latin */
@font-face {
	font-family: Nunito;
	font-style: normal;
	font-weight: 600;
	src:
		local(''),
		url('../fonts/nunito-v25-latin/nunito-v25-latin-600.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('../fonts/nunito-v25-latin/nunito-v25-latin-600.woff')
			format('woff'),
		/* Modern Browsers */
			url('../fonts/nunito-v25-latin/nunito-v25-latin-600.ttf')
			format('truetype'); /* Safari, Android, iOS */
}

/* nunito-700 - latin */
@font-face {
	font-family: Nunito;
	font-style: normal;
	font-weight: 700;
	src:
		local(''),
		url('../fonts/nunito-v25-latin/nunito-v25-latin-700.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('../fonts/nunito-v25-latin/nunito-v25-latin-700.woff')
			format('woff'),
		/* Modern Browsers */
			url('../fonts/nunito-v25-latin/nunito-v25-latin-700.ttf')
			format('truetype'); /* Safari, Android, iOS */
}
