@import "/home/runner/work/vi-saas-frontend/vi-saas-frontend/src/resources/styles/settings.scss"; @import "/home/runner/work/vi-saas-frontend/vi-saas-frontend/src/extensions/resources/styles/settings.scss";  .modal {
	position: fixed;
	z-index: 9999;

	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: $white;

	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}
