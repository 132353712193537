@import "/home/runner/work/vi-saas-frontend/vi-saas-frontend/src/resources/styles/settings.scss"; @import "/home/runner/work/vi-saas-frontend/vi-saas-frontend/src/extensions/resources/styles/settings.scss";  .headline {
	margin: 0;
	padding: 0;
	font-family: $font-family-sans-serif;
	font-weight: $font-weight-medium;

	&--1 {
		font-size: 40px;
		line-height: 50px;
		margin-bottom: $grid-base-five;
		font-weight: $headline-1-font-weight;
	}

	&--2 {
		font-size: 30px;
		line-height: 38px;
		margin-bottom: $grid-base-three;
		font-weight: $headline-2-font-weight;
	}

	&--3 {
		font-size: 24px;
		line-height: 32px;
	}

	&--4 {
		font-size: 20px;
		line-height: 26px;
	}

	&--5 {
		font-size: 16px;
		line-height: 21px;
	}
}
